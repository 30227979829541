<div class="modal-header">
    <span class="modal-title"><b>Kies een versie</b></span>
</div>
<div class="modal-body" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
    <table style="width: 100%;">
        <tr *ngFor="let grossier of wholesalers; let even = even; let odd = odd" class="table-detail-row" [ngClass]="{'table-detail-row-even':even,'table-detail-row-odd':!even}" style="cursor: pointer;">
            <td (click)="choose(grossier)">
                {{grossier.ShortName}}
            </td>
            <td><span class="float-end" (click)="deleteCookiesWithConfirm(grossier)"><i class="far fa-trash-alt"></i></span></td>
        </tr>
    </table>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="choose(null)">Annuleren</button>
</div>