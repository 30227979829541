<div class="container-fluid h-100"
    [ngStyle]="{'background': ctx.Layout.Background ? 'url(\'/assets' + ctx.Layout.Background + '\') no-repeat center center fixed' : '', '-webkit-background-size': 'cover', '-moz-background-size': 'cover', 'background-size': 'cover', '-o-background-size': 'cover'}">
    <div class="row justify-content-center align-items-center h-100">
        <div class="col col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="card login-panel">
                <div class="card-body text-bg-warning"
                    (contextmenu)="authService.chooseVersion($event, ctx.Grossiers, wholesalerChange)">
                    <span *ngIf="authService.multiLogonData && authService.multiLogonData.length > 1"
                        (click)="authService.chooseCookieList(ctx.Wholesaler, false)" class="float-end pointer"><i
                            class="fas fa-users"></i></span>
                    <span class="card-title text-primary my-2">Login</span>
                    <form accept-charset="UTF-8" role="form">
                        <div class="form-group">
                            <input class="form-control m-2" [appAutofocus]="true" placeholder="Loginnaam"
                                name="Username" [(ngModel)]="authService.loginData.Username" type="text"
                                (keyup.enter)="keytab($event)">
                            <input class="form-control m-2" placeholder="Wachtwoord" name="Password"
                                [(ngModel)]="authService.loginData.Password" [type]="isPwVisible ? 'text' : 'password'"
                                (dblclick)="isPwVisible = mainService.swicthIfWithAltShift($event, isPwVisible)"
                                (keyup.enter)="authService.doLogin(ctx.Wholesaler)">
                        </div>
                        <div class="checkbox m-2 text-center"><label><input name="remember" type="checkbox" [(ngModel)]="authService.loginData.KeepLogin"> Logingegevens onthouden</label></div>
                        <button class="btn btn-primary w-100" (click)="authService.doLogin(ctx.Wholesaler)">Versturen</button>
                        <app-manage-account></app-manage-account>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>