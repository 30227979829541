import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, config } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

import { Credentials } from '../_models/logon/credentials';
import { ShopPortalLogon } from '../_models/logon/shop.portal.logon';
import { MainService } from './main.service';
import { ShopPortalLogonRequest } from '../_models/logon/shop.portal.logon.request';
import { ApiService } from './api.service';
import { ChooseVersionComponent } from '../login/choose-version/choose-version.component';
import { ChooseCookieComponent } from '../login/choose-cookie/choose-cookie.component';
import { ShopService } from './shop.service';
import { CarTypeService } from './car-type.service';
import { CatalogService } from './catalog.service';
import { PromotionService } from './promotion.service';
import { RimAndTyreService } from './rim-and-tyre.service';
import { CustomerInformationService } from './customer-information.service';
import { ItemInfoService } from './item-info.service';
import { AdminService } from './admin/admin.service';
import { WholesalerModel } from '../_models/common/wholesaler.model';
import { ShopPortalMessage } from '../_models/common/shop.portal.message';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ShopSoort } from '../_models/common/shop.soort';
import { AdminSettingsService } from './admin/admin-settings.service';
import { AdminPromotionService } from './admin/admin-promotion.service';
import { AdminLoyaltyShopService } from './admin/admin-loyalty-shop.service';
import { ConfigData } from '../_models/common/config.data';
import { PortalSettingsService } from './portal-settings.service';
import { LoyaltyShopService } from './loyalty-shop.service';
import { FavoritesService } from './favorites.service';
import { LicensePlateService } from './license-plate.service';

@Injectable()
export class AuthService {
  currentLogon: ShopPortalLogon;
  loginData: Credentials = new Credentials('', '', false);
  multiLogonData: Credentials[];
  returnUrl: string;
  loginBusy: boolean;
  // extraPassword: string;

  constructor(
    private configData: ConfigData,
    private mainService: MainService,
    private apiService: ApiService,
    private portalSettingsService: PortalSettingsService,
    private carTypeService: CarTypeService,
    private catalogService: CatalogService,
    private itemInfoService: ItemInfoService,
    private licensePlateService: LicensePlateService,
    private loyaltyShopService: LoyaltyShopService,
    private promotionService: PromotionService,
    private favoritesService: FavoritesService,
    private rimAndTyreService: RimAndTyreService,
    private shopService: ShopService,
    private adminService: AdminService,
    private adminSettingsService: AdminSettingsService,
    private adminPromotionService: AdminPromotionService,
    private adminLoyaltyShopService: AdminLoyaltyShopService,
    private customerInformationService: CustomerInformationService,
    private http: HttpClient,
    private modalService: BsModalService,
    private router: Router,
  ) {
    this.currentLogon = JSON.parse(localStorage.getItem('currentLogon')) as ShopPortalLogon;
  }

  clear(): void {
    localStorage.removeItem('currentLogon');
    this.currentLogon = null;
    // this.loginData = new Credentials('', '', false);
  }

  getExtraPassword(): string {
    return this.customerInformationService.password;
  }

  processLogonResponse(logon: ShopPortalLogon) {

  }

  getShopPortalLogon(logonRequest: ShopPortalLogonRequest): Observable<ShopPortalLogon> {
    return this.http.post<ShopPortalLogon>(`${this.configData.backendApi}/auth/logon`, logonRequest)
      .pipe(mergeMap(logon => {
        if (logon) {
          // this.configData.backendApiVersion = tokenResponse.Version;
          this.currentLogon = logon;
          localStorage.setItem('currentLogon', JSON.stringify(logon));
          console.info(`getShopPortalLogon: ok! Session: ${this.currentLogon.Session} expiration: ${this.currentLogon.Expiration}`);
          return of(logon);

        } else {
          console.warn(`getShopPortalLogonBackendApi: ERROR! logonRequest: ${JSON.stringify(logonRequest)}`);
          return of(null);
        }
      }));
  }

  private getShopPortalLogonByTicket(ticket: string, frontendUrl: string): Observable<ShopPortalLogon> {
    const logonRequest = new ShopPortalLogonRequest(
      0, '__TICKET__', ticket, frontendUrl, this.configData.backendApi, false, environment.production, environment.version, 0);
    console.info('getShopPortalLogonByTicket: ShopPortalLogonRequest -> ', logonRequest);
    return this.http.post<ShopPortalLogon>(this.configData.backendApi + '/auth/logon', logonRequest)
      .pipe(
        mergeMap((logon: ShopPortalLogon) => {
          // this.configData.backendApiVersion = tokenResponse.Version;
          this.currentLogon = logon;
          localStorage.setItem('currentLogon', JSON.stringify(logon));
          console.info(`getShopPortalLogonByTicket: ok! Session: ${this.currentLogon.Session} expiration: ${this.currentLogon.Expiration}`);
          return of(logon);
        })
      );
  }

  public getNewLogon(session: number): Observable<ShopPortalLogon> {
    const grossier = this.mainService.getGrossierFromUniqueID(this.currentLogon.UniqueID);
    const request = new ShopPortalLogonRequest(
      grossier,
      this.currentLogon.Login,
      this.currentLogon.Password,
      document.location.href,
      this.configData.backendApi,
      this.currentLogon.KeepLogin,
      environment.production,
      environment.version,
      session
    );
    return this.getShopPortalLogon(request);
  }

  public getCurrentLogon(): Observable<ShopPortalLogon> {
    const now = new Date();
    if (this.currentLogon && this.currentLogon.Expiration > now) {
      return of(this.currentLogon);
    } else if (this.currentLogon) {
      console.info(`Token expired ${this.currentLogon.Expiration}.. Session: ${this.currentLogon.Session} -> getting new token...`);
      return this.getNewLogon(this.currentLogon.Session);
    } else {
      return of(null);
    }
  }

  public chooseVersion(event: MouseEvent, wholesalers: WholesalerModel[], wholesalerChange: EventEmitter<{}>): boolean {
    if ((event.shiftKey && !event.altKey && event.ctrlKey) || (event.altKey && !event.shiftKey && event.ctrlKey)) {
      const initialState = {
        wholesalers: wholesalers,
        mainService: this.mainService,
        authService: this
      };
      const modalRef = this.modalService.show(ChooseVersionComponent, { initialState });
      modalRef.content.onClose.subscribe(grossier => {
        wholesalerChange.emit(grossier);
      });
    } else if (event.shiftKey && event.altKey && !event.ctrlKey) {
      this.mainService.showObject(null, this.configData.ctxPublic);
    }
    return false;
  }

  public chooseCookieList(wholesaler: number, switchUser: boolean): void {
    const initialState = { wholesaler: wholesaler, authService: this };
    const modalRef = this.modalService.show(ChooseCookieComponent, { initialState, class: 'modal-sm' });
    modalRef.content.onClose.subscribe(credentials => {
      if (credentials) {
        this.loginData = credentials;
        if (switchUser) {
          this.router.navigate(['/switch-user']);
        } else {
          this.doLogin(wholesaler);
        }
      }
    });
  }

  public switchUser(): void {
    if (this.currentLogon && this.currentLogon.UniqueID) {
      const grossier = this.mainService.getGrossierFromUniqueID(this.currentLogon.UniqueID);
      this.returnUrl = '';
      this.chooseCookieList(grossier, true);
    }
  }

  public getCookieList(grossier: number): Credentials[] {
    const data = localStorage.getItem(`PortalCookieList_${grossier}`);
    if (data) { return JSON.parse(data) as Credentials[]; }
    return [];
  }

  public getCookieSingle(grossier: number): Credentials {
    const data = localStorage.getItem(`PortalCookieSingle_${grossier}`);
    if (data) { return JSON.parse(data) as Credentials; }
    return null;
  }

  public saveCookieList(grossier: number, data: Credentials[]): void {
    localStorage.setItem(`PortalCookieList_${grossier}`, JSON.stringify(this.removeDuplicateLogins(data)));
  }

  removeDuplicateLogins(data: Credentials[]): Credentials[] {
    const list: Credentials[] = [];
    data.forEach(credential => {
      if (!(list.some(c => c.Username === credential.Username))) {
        list.push(credential);
      }
    });
    return list;
  }

  private saveCookieSingle(grossier: number, data: Credentials): void {
    localStorage.setItem(`PortalCookieSingle_${grossier}`, JSON.stringify(data));
  }

  private removeCookieList(grossier: number): void {
    localStorage.removeItem(`PortalCookieList_${grossier}`);
  }

  public removeCookieSingle(grossier: number): void {
    localStorage.removeItem(`PortalCookieSingle_${grossier}`);
  }

  public removeAllCookies(grossier: number): void {
    this.removeCookieList(grossier);
    this.removeCookieSingle(grossier);
  }

  private updateCookie(logon: ShopPortalLogon) {
    const grossier = this.mainService.getGrossierFromUniqueID(logon.UniqueID);
    let data = this.getCookieList(grossier);
    const credentials = new Credentials(logon.Login, logon.Password, logon.KeepLogin);
    data = data.filter(cred => !(cred.Username === credentials.Username && cred.Password === credentials.Password));
    if (credentials.KeepLogin) {
      this.saveCookieSingle(grossier, credentials);
      data.unshift(credentials);
    } else {
      this.removeCookieSingle(grossier);
    }
    this.saveCookieList(grossier, data);
    this.multiLogonData = data;
  }

  public doLogin(wholesaler: number): void {
    this.doLogout()
      .subscribe(ok => {
        this.loginBusy = true;
        const request = new ShopPortalLogonRequest(
          wholesaler,
          this.loginData.Username,
          this.loginData.Password,
          document.location.href,
          this.configData.backendApi,
          this.loginData.KeepLogin,
          environment.production,
          environment.version,
          0
        );
        this.getShopPortalLogon(request)
          .subscribe(
            (logon: ShopPortalLogon) => {
              if (logon && logon.UniqueID) {
                this.updateCookie(logon);
                if (logon.Role > 0) {
                  console.info(`doLogin -> Admin Session`);
                  this.router.navigate(['/admin']);
                } else {
                  this.loginBusy = false;
                  this.mainService.getContextMain('')
                    .subscribe((ctx) => {
                      this.mainService.removeNotActivatedShopModulesFromMenu(this.shopService, ctx);
                      if (this.returnUrl) {
                        console.info(`doLogin -> returnUrl: ${this.returnUrl}`);
                        this.router.navigate([this.returnUrl]);
                      } else {
                        if (!this.shopService.initialized) { this.shopService.initShopService(this.mainService); }
                        console.info(`doLogin -> goto StartupModule ${ShopSoort[this.shopService.startUpShop]}`);
                        this.shopService.gotoStartupModule(ctx);
                      }
                    });
                }
              } else if (logon) {
                console.warn(`doLogin: not ok! ${logon.Token}`);
                const message = new ShopPortalMessage('Let op!', logon.Token, null, logon.Timing);
                this.mainService.msgBoxExtended(message);
              }
            },
            (err: HttpErrorResponse) => {
              this.loginBusy = false;
              console.error(`doLogin: error! ${err.statusText}`);
              this.mainService.msgBox('Let op!', err.statusText);
            });
      });
  }

  public doLoginByTicket(ticket: string): void {
    this.loginBusy = true;
    this.getShopPortalLogonByTicket(ticket, document.location.origin)
      .subscribe(
        (logon: ShopPortalLogon) => {
          if (logon && logon.UniqueID) {
            if (this.currentLogon) { this.doLogout(); }
            this.updateCookie(logon);
            this.loginBusy = false;
            this.mainService.getContextMain(ticket)
              .subscribe((ctx) => {
                console.info('doLoginByTicket: ok!');
                if (ctx && ctx.CurrentAuto) {
                  console.info(`doLoginByTicket: set current car: ${ctx.CurrentAuto.ShortDescription}`);
                  this.carTypeService.currentCarType = ctx.CurrentAuto;
                }
                this.router.navigate(['/']);
              });
          } else if (logon) {
            console.warn(`doLoginByTicket: not ok! ${logon.Token}`);
            const message = new ShopPortalMessage('Let op!', logon.Token, null, logon.Timing);
            this.mainService.msgBoxExtended(message);
          }
        },
        (err: HttpErrorResponse) => {
          this.loginBusy = false;
          console.error(`doLoginByTicket: error! ${err.statusText}`);
          this.mainService.msgBox('Let op!', err.statusText);
        });
  }

  public gotoLogout(): void {
    this.router.navigate(['/logout']);
  }

  private doLogoutFrontend() {
    this.portalSettingsService.clear();
    this.customerInformationService.clear();
    this.carTypeService.clear();
    // this.cartService.clear();
    this.catalogService.clear();
    this.itemInfoService.clear();
    this.licensePlateService.clear();
    this.loyaltyShopService.clear();
    this.promotionService.clear();
    this.favoritesService.clear();
    this.rimAndTyreService.clear();
    this.shopService.clear();
    this.adminService.clear();
    this.adminSettingsService.clear();
    this.adminPromotionService.clear();
    this.adminLoyaltyShopService.clear();
    this.mainService.clear();
    this.clear();
  }

  public doLogout(): Observable<boolean> {
    if (this.currentLogon && this.currentLogon.UniqueID) {
      console.info('doLogout...');
      return this.apiService.doLogout()
        .pipe(
          mergeMap(ok => {
            this.doLogoutFrontend();
            console.info(`doLogout: ok = ${ok}!`);
            return of(ok);
          }),
          catchError(err => {
            console.error('doLogout error:', err);
            return of(true);
          })
        );
    } else {
      return of(true);
    }
  }

  public getAuthorizationToken(): string {
    if (this.currentLogon) {
      return this.currentLogon.Token;
    }
    return null;
  }

}
