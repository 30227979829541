<div id="boven">
    <img src="/assets/img/identity/hofland/shopportal_hofland.jpg" height="350" alt="" />
</div>
<div id="midden">
    <div id="inlog">
        <div class="left-login">
            <table style="width: 370px; border-collapse: unset; border-spacing: 4px;" border="0" cellpadding="4" cellspacing="4" class="loginform">
                <tr>
                    <td colspan="2" (contextmenu)="authService.chooseVersion($event, ctx.Grossiers, wholesalerChange)">
                        <span *ngIf="authService.multiLogonData && authService.multiLogonData.length > 1" (click)="authService.chooseCookieList(ctx.Wholesaler, false)" class="float-end pointer"><i class="fas fa-users"></i></span>
                        <h3>Inloggen</h3>
                    </td>
                </tr>
                <tr>
                    <td width="28%">Gebruikersnaam:</td>
                    <td width="72%">
                        <input [appAutofocus]="true" placeholder="Loginnaam" name="Username" [(ngModel)]="authService.loginData.Username" type="text" (keyup.enter)="keytab($event)" ID="txtLogin" class="required" style="font-weight:bold; font-size: 14px; color: red; width: 210px;">
                </tr>
                <tr>
                    <td>Wachtwoord:</td>
                    <td>
                        <input name="Password" [(ngModel)]="authService.loginData.Password" [type]="isPwVisible ? 'text' : 'password'" (dblclick)="isPwVisible = mainService.swicthIfWithAltShift($event, isPwVisible)" (keyup.enter)="authService.doLogin(ctx.Wholesaler)" ID="txtPassword" class="required password" style="font-weight:bold; font-size: 14px; color: red; width: 210px;">
                </tr>
                <tr>
                    <td colspan="2">
                        <div align="center">
                            <input type="checkbox" [(ngModel)]="authService.loginData.KeepLogin"> Onthoud mijn wachtwoord
                            <!--|  <a href="wachtwoord-vergeten.php">Wachtwoord vergeten ?</a>-->
                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="59" colspan="2">
                        <div align="center">
                            <p>
                                <input type="button" style="padding: 4px 10px;" (click)="authService.doLogin(ctx.Wholesaler)" value="Login" />
                            </p>
                            <app-manage-account></app-manage-account>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>