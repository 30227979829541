<ng-container *ngIf="!response; else responseExists">
  <div class="alert alert-warning" role="alert">
    Geen antwoord ontvangen!
  </div>
</ng-container>

<ng-template #responseExists>

  <div *ngIf="!response.IsOk; else responseOk" class="alert alert-danger" role="alert">
    Er is een fout opgetreden!
    {{response.ErrorMessage}}
  </div>

  <ng-template #responseOk>
    <div class="small mx-3">
      @for (item of response.Items | keyvalue; track item; let index = $index) {
      <div class="row mb-3"
        [ngClass]="{'bg-body-tertiary': index%2===0, 'border-top': index%2===0, 'border-bottom': index%2===0 }">
        @if (!withoutFirstColumn) {
        <div class="col-lg-2 mb-3">
          <b>{{item.key}}</b><br />
          <span *ngIf="item.value.Properties['SupplierId']" class="text-low small">SupplierId:
            {{item.value.Properties['SupplierId']}}</span>
        </div>
        }
        <div class="col-lg-4 mb-3">
          <span class="fw-bold pointer"
            (click)="searchAction.emit('$'+item.value.ItemNumber)">{{item.value.ItemNumber}}</span><span
            *ngIf="item.value.ItemGroup" class="ms-1 text-muted">({{item.value.ItemGroup}})</span><br />
          <span *ngIf="item.value.Description">{{item.value.Description}}<br /></span>
          <table class="table table-striped table-bordered table-sm small">
            <tbody>
              <tr *ngFor="let property of getProperties(item.value)">
                <td class="text-muted">{{property}}</td>
                <td class="text-muted">{{item.value.Properties[property]}}</td>
              </tr>
            </tbody>
          </table>
          <span *ngIf="item.value.Remark">{{item.value.Remark}}</span>
          <div class="row g-0">
            @if (item.value.PriceInfo.GrossPrice) {
            <div class="col-5">bruto</div>
            <div class="col-7 text-end">{{item.value.PriceInfo.GrossPrice | price}}</div>
            }
            @if (item.value.PriceInfo.NetPrice) {
            <div class="col-5">netto</div>
            <div class="col-7 text-end">{{item.value.PriceInfo.NetPrice | price}}</div>
            }
            @if (item.value.PriceInfo.Deposit) {
            <div class="col-6">statiegeld</div>
            <div class="col-6 text-end">{{item.value.PriceInfo.Deposit | price}}</div>
            }
            @if (item.value.PriceInfo.Discount) {
            <div class="col-5">korting</div>
            <div class="col-7 text-end">{{item.value.PriceInfo.Discount | price}}</div>
            }
            @if (item.value.PriceInfo.VAT) {
            <div class="col-5">BTW</div>
            <div class="col-7 text-end">{{item.value.PriceInfo.VAT | price}}</div>
            }
            @if (item.value.PriceInfo.Currency) {
            <div class="col-9">munteenheid</div>
            <div class="col-3 text-end">{{item.value.PriceInfo.Currency}}</div>
            }
          </div>
        </div>
        <div class="col-lg-6">
          @for (depot of item.value.Depots; track depot.DepotID; let index2 = $index) {
          <div class="row mt-2" [ngClass]="{'bg-light-subtle': index2%2===1 }">
            <div class="col-12">
              <table class="table table-warning table-bordered table-striped table-sm w-100">
                <tbody>
                  <tr>
                    <td class="ps-2 w-50"><b>{{depot.DepotName}}</b><span *ngIf="depot.DepotID"
                        class="ms-1 text-body-tertiary">({{depot.DepotID}})</span></td>
                    <td class="sm-td w-50">
                      <span *ngIf="depot.Availability==availabilityCode.NotInUse">{{depot.Quantity}}</span>
                      <span *ngIf="depot.Availability==availabilityCode.InStock">op voorraad</span>
                      <span *ngIf="depot.Availability==availabilityCode.LowOnStock">weinig voorraad</span>
                      <span *ngIf="depot.Availability==availabilityCode.OutOfStock">geen voorraad</span>
                      <span *ngIf="depot.Availability==availabilityCode.SufficientlyInStock">voldoende
                        voorraad</span>
                    </td>
                  </tr>
                  <tr *ngIf="isDateOk(depot.LastUpdated)">
                    <td class="sm-td-l">laatste update</td>
                    <td class="sm-td">{{depot.LastUpdated | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="isDateOk(depot.OrderBeforeDateTime)">
                    <td class="sm-td-l">bestel voor</td>
                    <td class="sm-td">{{depot.OrderBeforeDateTime | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="isDateOk(depot.DeliveryDateTime)">
                    <td class="sm-td-l">levertijd</td>
                    <td class="sm-td">{{depot.DeliveryDateTime | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="depot.ShippingMethod">
                    <td class="sm-td-l">verzendwijze</td>
                    <td class="sm-td">{{depot.ShippingMethod}}</td>
                  </tr>
                  <tr *ngIf="depot.ShippingCosts">
                    <td class="sm-td-l">verzendkosten</td>
                    <td class="sm-td">{{depot.ShippingCosts | price}}</td>
                  </tr>
                  <tr *ngIf="depot.Remark">
                    <td class="sm-td-l">opmerking</td>
                    <td class="sm-td">{{depot.Remark}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          }
        </div>
      </div>
      }
    </div>
  </ng-template>

</ng-template>