import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BsModalService } from 'ngx-bootstrap/modal';

import { AddressModel } from '../../_models/admin/supplier-interface/address.model';
import { InterfaceData } from '../../_models/admin/supplier-interface/interface.data';
import { InterfaceKindInfoModel } from '../../_models/admin/supplier-interface/interface.kind.info.model';
import { SupplierInterfaceData } from '../../_models/admin/supplier-interface/supplier.interface.data';
import { BranchModel } from '../../_models/common/branch.model';
import { ApiService } from '../api.service';
import { MainService } from '../main.service';
import { ToasterService } from '../toaster.service';
import { AdminService } from './admin.service';
import { DefaultInterfaceDataEditComponent } from '../../admin/super-admin/admin-supplier-interfaces/default-supplier-interface-settings/default-interface-data-settings/default-interface-data-edit/default-interface-data-edit.component';
import { UpdateSupplierInterfaceDataRequest } from '../../_models/admin/supplier-interface/update.supplier.interface.data.request';
import { ProxyInterfaceDataEditComponent } from '../../admin/super-admin/admin-supplier-interfaces/proxy-supplier-interface-settings/proxy-interface-data-settings/proxy-interface-data-edit/proxy-interface-data-edit.component';


@Injectable({
  providedIn: 'root'
})
export class AdminSupplierInterfaceService {

  constructor(
    private adminService: AdminService,
    private apiService: ApiService,
    private mainService: MainService,
    private toasterService: ToasterService,
    private modalService: BsModalService
  ) { }

  getSupplierInterfaceData(capabilities: number): Observable<SupplierInterfaceData> {
    return this.apiService.adminGetSupplierInterfaceData(this.adminService.currentUid, capabilities);
  }

  getInterfaceInfosForSupplier(supplierInterfaceData: SupplierInterfaceData, supplier: number): InterfaceKindInfoModel[] {
    const interfaceKindInfos = this.getSortedInterfaceKindInfos(supplierInterfaceData.InterfaceKindInfos)
      .filter(interfaceKind => {
        let ok = false;
        if (supplierInterfaceData.InterfaceData.some(i => i.Supplier === supplier && i.IntType === interfaceKind.InterfaceKind)) { ok = true; }
        return ok;
      });
    return interfaceKindInfos;
  }

  getInterfaceDataForInterfaceKindAndSupplier(supplierInterfaceData: SupplierInterfaceData, interfaceKind: number, supplier: number): InterfaceData {
    const interfaceDatas = supplierInterfaceData.InterfaceData
      .filter(interfaceData => interfaceData.IntType === interfaceKind && interfaceData.Supplier === supplier);
    return interfaceDatas[0];
  }

  

  filterInterfaceDatas(interfaceDatas: InterfaceData[], filterString: string, supplierInterfaceData: SupplierInterfaceData, filterUnknown: boolean): any {
    if (!filterString && !filterUnknown) { return interfaceDatas; }
    return interfaceDatas.filter(interfaceData => {
      const search = filterString.toLowerCase();
      if (interfaceData) {
        if (filterUnknown && !this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, Math.abs(interfaceData.IntType)).InterfaceKind) { return false; }
        if (interfaceData.IntType == +search) { return true; }
        if (interfaceData.Wholesaler == +search) { return true; }
        if (interfaceData.Supplier == +search) { return true; }
        if (interfaceData.Username.toLowerCase().indexOf(search) >= 0) { return true; }
        if (this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, interfaceData.IntType).Name.toLowerCase().indexOf(search) >= 0) { return true; }
        if (this.getSupplierName(supplierInterfaceData.SupplierData, interfaceData.Supplier).toLowerCase().indexOf(search) >= 0) { return true; }
        if (this.getBranch(supplierInterfaceData.Branches, interfaceData.Branch).BranchName.toLowerCase().indexOf(search) >= 0) { return true; }
        if (interfaceData.Url.toLowerCase().indexOf(search) >= 0) { return true; }
        if (interfaceData.Username.toLowerCase().indexOf(search) >= 0) { return true; }
        if (interfaceData.Password.toLowerCase().indexOf(search) >= 0) { return true; }
        if (interfaceData.CustomerCode.toLowerCase().indexOf(search) >= 0) { return true; }
        if (interfaceData.SupplierCode.toLowerCase().indexOf(search) >= 0) { return true; }
      }
      return false;
    });
  }

  getSortValue(a: InterfaceKindInfoModel, b: InterfaceKindInfoModel): number {
    if (a.SortOrder < b.SortOrder) { return -1; }
    if (a.SortOrder > b.SortOrder) { return 1; }
    if (a.Name < b.Name) { return -1; }
    if (a.Name > b.Name) { return 1; }
    return 0;
  }

  getSortedInterfaceKindInfos(interfaceKindInfos: { [key: number]: InterfaceKindInfoModel }): InterfaceKindInfoModel[] {
    const sorted = Object.values(interfaceKindInfos).sort((a, b) => this.getSortValue(a, b));
    return sorted;
  }

  getInterfaceDataGrouped(supplierInterfaceData: SupplierInterfaceData, interfaceData: InterfaceData[]): { intType: number, interfaceDatas: { [key: number]: InterfaceData[] } }[] {
    const groups = interfaceData.reduce((group, interfaceData) => {
      const kind = this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, Math.abs(interfaceData.IntType));
      const intType = kind.InterfaceKind;
      if (!group[intType]) group[intType] = {};
      if (!group[intType][interfaceData.Supplier]) group[intType][interfaceData.Supplier] = [];
      group[intType][interfaceData.Supplier].push(interfaceData);
      return group;
    }, {});

    const sortedTypes = Object.keys(groups).sort((a, b) => {
      const kindA = this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, +a);
      const kindB = this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, +b);
      return this.getSortValue(kindA, kindB);
    });

    const result = sortedTypes.map(t => { return { 'intType': +t, 'interfaceDatas': groups[t] }; });

    return result;
  }

  getInterfaceTypeInfo(interfaceKindInfos: { [key: number]: InterfaceKindInfoModel }, intType: number): InterfaceKindInfoModel {
    if (interfaceKindInfos && interfaceKindInfos[intType]) {
      return interfaceKindInfos[intType];
    }
    if (interfaceKindInfos) {
      let interfaceKindInfo: InterfaceKindInfoModel = null;
      Object.keys(interfaceKindInfos).forEach(t => {
        if (interfaceKindInfos[t].LinkedInterfaceKinds?.length) {
          interfaceKindInfos[t].LinkedInterfaceKinds.forEach(linkedInfo => {
            if (linkedInfo.InterfaceKind === intType) interfaceKindInfo = linkedInfo;
          });
        }
      });
      if (interfaceKindInfo) { return interfaceKindInfo; }
    }
    if (interfaceKindInfos && interfaceKindInfos[0]) {
      return interfaceKindInfos[0];
    }
    const result = new InterfaceKindInfoModel();
    result.InterfaceKind = 0;
    result.Name = "Onbekend";
    result.SortOrder = -1;
    return result;
  }

  hasInterfaceDataType(interfaceDatas: InterfaceData[], intType: number): boolean {
    for (let index = 0; index < interfaceDatas.length; index++) {
      if (interfaceDatas[index].IntType === intType) { return true; }
    }
    return false;
  }

  getSupplierName(supplierData: { [key: number]: AddressModel; }, supplierId: number): string {
    if (supplierData && supplierData[supplierId]) {
      return supplierData[supplierId].Name;
    }
    return 'Onbekend';
  }

  getBranch(branchData: { [key: number]: BranchModel }, branchNumber: number): BranchModel {
    if (branchData && branchData[branchNumber]) { return branchData[branchNumber]; }
    const unknown = new BranchModel();
    unknown.BranchNumber = -1;
    unknown.BranchName = 'Onbekend';
    unknown.BranchNameShort = 'Onbekend';
    unknown.BranchNameAbbreviated = '?';
    return unknown;
  }

  isUnique(interfaceDatas: InterfaceData[], currentInterfaceData: InterfaceData, editedInterfaceData: InterfaceData) {
    if (currentInterfaceData.Branch === editedInterfaceData.Branch
      && currentInterfaceData.IntType === editedInterfaceData.IntType
      && currentInterfaceData.Supplier === editedInterfaceData.Supplier) { return true; }
    for (let index = 0; index < interfaceDatas.length; index++) {
      if (interfaceDatas[index].Branch === editedInterfaceData.Branch
        && interfaceDatas[index].IntType === editedInterfaceData.IntType
        && interfaceDatas[index].Supplier === editedInterfaceData.Supplier) { return false; }
    }
    return true;
  }

  isDisabledUnique(interfaceDatas: InterfaceData[], interfaceData: InterfaceData) {
    if (interfaceData.IntType === 0) { return false; }
    for (let index = 0; index < interfaceDatas.length; index++) {
      if (interfaceDatas[index].Branch === interfaceData.Branch
        && interfaceDatas[index].IntType === (interfaceData.IntType * -1)
        && interfaceDatas[index].Supplier === interfaceData.Supplier) { return false; }
    }
    return true;
  }

  editInterfaceData(supplierInterfaceData: SupplierInterfaceData, interfaceData: InterfaceData, interfaceDataChanged: EventEmitter<SupplierInterfaceData>, addNew = false) {
    const initialState = {
      supplierInterfaceData: supplierInterfaceData,
      interfaceData: interfaceData,
      addNew: addNew,
      mainService: this.mainService,
      adminSupplierInterfaceService: this
    };

    const modalRef = this.modalService.show(DefaultInterfaceDataEditComponent, { initialState, class: 'modal-lg' });

    modalRef.content.onClose
      .subscribe(confirm => {
        this.saveInterfaceData(supplierInterfaceData, interfaceData, confirm, interfaceDataChanged, addNew);
      });
  }

  editProxyInterfaceData(supplierInterfaceData: SupplierInterfaceData, interfaceData: InterfaceData, interfaceDataChanged: EventEmitter<SupplierInterfaceData>, addNew = false) {
    const initialState = {
      supplierInterfaceData: supplierInterfaceData,
      interfaceData: interfaceData,
      addNew: addNew,
      mainService: this.mainService,
      adminSupplierInterfaceService: this
    };

    const modalRef = this.modalService.show(ProxyInterfaceDataEditComponent, { initialState, class: 'modal-lg' });

    modalRef.content.onClose
      .subscribe(confirm => {
        this.saveInterfaceData(supplierInterfaceData, interfaceData, confirm, interfaceDataChanged, addNew);
      });
  }

  saveInterfaceData(supplierInterfaceData: SupplierInterfaceData, oldInterfaceData: InterfaceData, newInterfaceData: InterfaceData, interfaceDataChanged: EventEmitter<SupplierInterfaceData>, newlyAdded = false) {
    if (newInterfaceData) {
      const updateRequest = new UpdateSupplierInterfaceDataRequest();
      const addNew = newlyAdded;
      updateRequest.Updated = [newInterfaceData];
      if (!addNew && oldInterfaceData != null && (oldInterfaceData.Branch != newInterfaceData.Branch || oldInterfaceData.IntType != newInterfaceData.IntType || oldInterfaceData.Supplier != newInterfaceData.Supplier)) {
        updateRequest.Deleted = [oldInterfaceData];
      }
      this.apiService.adminUpdateInterfaceData(this.adminService.currentUid, updateRequest)
        .subscribe(newSupplierInterfaceData => {
          let supplier = this.getSupplierName(supplierInterfaceData.SupplierData, oldInterfaceData?.Supplier);
          if (supplier != 'Onbekend') { supplier = `van ${supplier}`; } else { supplier = ''; }
          if (newSupplierInterfaceData) {
            let action = 'opgeslagen';
            if (addNew) {
              action = 'toegevoegd';
              interfaceDataChanged.emit(newSupplierInterfaceData);
            } else {
              interfaceDataChanged.emit(newSupplierInterfaceData);
            }
            this.toasterService.showToast('Succes', `De '${this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, oldInterfaceData?.IntType ?? newInterfaceData?.IntType).Name}' koppeling ${supplier} voor '${this.getBranch(supplierInterfaceData.Branches, oldInterfaceData?.Branch ?? newInterfaceData?.Branch).BranchName}' is ${action}.`);
          } else {
            this.toasterService.showToast('Let op!', `De '${this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, oldInterfaceData?.IntType ?? newInterfaceData?.IntType).Name}' koppeling ${supplier} voor '${this.getBranch(supplierInterfaceData.Branches, oldInterfaceData?.Branch ?? newInterfaceData?.Branch).BranchName}' is niet aangepast.`);
          }
        });
    }
  }

  deleteInterfaceData(supplierInterfaceData: SupplierInterfaceData, interfaceData: InterfaceData, interfaceDataChanged: EventEmitter<SupplierInterfaceData>) {
    let supplier = this.getSupplierName(supplierInterfaceData.SupplierData, interfaceData.Supplier);
    if (supplier != 'Onbekend') { supplier = `van ${supplier}`; } else { supplier = ''; }
    this.mainService.confirmBox(`Weet u zeker dat u de '${this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, interfaceData.IntType).Name}' koppeling ${supplier} wilt verwijderen voor '${this.getBranch(supplierInterfaceData.Branches, interfaceData.Branch).BranchName}'?`)
      .subscribe(yes => {
        if (yes) {
          const updateRequest = new UpdateSupplierInterfaceDataRequest();
          updateRequest.Deleted = [interfaceData];
          this.apiService.adminUpdateInterfaceData(this.adminService.currentUid, updateRequest)
            .subscribe(newSupplierInterfaceData => {
              if (newSupplierInterfaceData) {
                this.toasterService.showToast('Succes', `De '${this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, interfaceData.IntType).Name}' koppeling ${supplier}' voor '${this.getBranch(supplierInterfaceData.Branches, interfaceData.Branch).BranchName}' is verwijderd!`);
                interfaceDataChanged.emit(newSupplierInterfaceData);
              } else {
                this.toasterService.showToast('Let op!', `De '${this.getInterfaceTypeInfo(supplierInterfaceData.InterfaceKindInfos, interfaceData.IntType).Name}' koppeling ${supplier}' voor '${this.getBranch(supplierInterfaceData.Branches, interfaceData.Branch).BranchName}' is NIET verwijderd!`);
              }
            });
        }
      });
  }

  isProxyExtraLoggingSet(interfaceData: InterfaceData) {
    return interfaceData.CustomerCode.includes('ExtraLogging;');
  }

  setProxyExtraLogging(interfaceData: InterfaceData, extraLogging: boolean) {
    if (extraLogging && !this.isProxyExtraLoggingSet(interfaceData)) {
      interfaceData.CustomerCode += 'ExtraLogging;';
    }
    if (!extraLogging && this.isProxyExtraLoggingSet(interfaceData)) {
      interfaceData.CustomerCode = interfaceData.CustomerCode.replace('ExtraLogging;', '');
    }
  }
  isProxyTestUrlSet(interfaceData: InterfaceData) {
    return interfaceData.Url.includes('api-test.');
  }

  setProxyTestUrl(interfaceData: InterfaceData, testUrl: boolean) {
    if (testUrl && !this.isProxyTestUrlSet(interfaceData)) {
      interfaceData.Url = interfaceData.Url.replace('api.', 'api-test.');
    }
    if (!testUrl && this.isProxyTestUrlSet(interfaceData)) {
      interfaceData.Url = interfaceData.Url.replace('api-test.', 'api.');
    }
  }


}
