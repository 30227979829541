import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MainService } from '../_services/main.service';
import { ContextPublic } from '../_models/common/context.public';
import { AuthService } from '../_services/auth.service';
import { ResizeInfo } from '../_models/common/resize.info';
import { Credentials } from '../_models/logon/credentials';
import { ConfigData } from '../_models/common/config.data';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  constructor(
    public configData: ConfigData,
    private mainService: MainService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mainService.onResize(new ResizeInfo(document.body.clientWidth, document.body.clientHeight, 0, 0, 0));
  }

  ngOnInit() {
    this.doLogoutIfNeeded()
      .subscribe(didLogout => {
        if (this.router.url === '/reload') {
          window.location.href = '/?t=' + new Date().getTime();
        } else if (this.router.url === '/switch-user' && this.configData.ctxPublic) {
          this.authService.doLogin(this.configData.ctxPublic.Wholesaler);
        } else {
          this.authService.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
          if (this.configData.ctxPublic && !didLogout) {
            this.tryLoginByCookie(this.configData.ctxPublic);
          } else if (!this.configData.ctxPublic) {
            setTimeout(_ => {
              this.getContextPublic(0);
            }, 500);
          }
        }
      });
  }

  doLogoutIfNeeded(): Observable<boolean> {
    const url = this.router.url;
    if (url === '/logout' || url === '/reload') {
      return this.authService.doLogout()
        .pipe(mergeMap((ok) => {
          return of(true);
        }));
    } else {
      return of(false);
    }
  }

  tryLoginByCookie(ctx: ContextPublic): void {
    if (ctx) {
      const cookie = this.authService.getCookieSingle(ctx.Wholesaler);
      if (cookie) {
        this.authService.loginData = cookie;
        if (this.router.url !== '/logout') { this.authService.doLogin(this.configData.ctxPublic.Wholesaler); }
      } else {
        this.authService.loginData = new Credentials('', '', false);
      }
    } else {
      console.warn('No public context at login component!');
    }
  }

  ngAfterViewInit() {
    // wait a tick to avoid one-time devMode
    // unidirectional-data-flow-violation error
    const self = this;
    setTimeout(_ => {
      self.mainService.onResize(new ResizeInfo(document.body.clientWidth, document.body.clientHeight, 0, 0, 0));
    }, 500);
  }

  getContextPublic(wholesaler: number): void {
    this.mainService.getContextPublic(wholesaler)
      .subscribe(ctx => {
        this.authService.multiLogonData = this.authService.getCookieList(ctx.Wholesaler);
        this.tryLoginByCookie(ctx);
      });
  }

}
