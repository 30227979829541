import { RelationType } from './relation.type';
import { CustomerRegion } from './customer.region';
import { UserModel } from './user.model';

export class CustomerModel {
    CustomerNumber: number;
    UserID: number;
    BranchNumber: number;
    Active: boolean;
    Name: string;
    Contact: string;
    Phone: string;
    Fax: string;
    Mobile: string;
    Email: string;
    StreetName: string;
    HouseNumber: string;
    PostalCode: string;
    City: string;
    Country: string;
    ShortName: string;
    CustomerType: RelationType;
    CustomerTypeWebAlias: RelationType;
    SalesBlock: boolean;
    CreditPeriod: number;
    CreditPeriodBlockage: boolean;
    CreditLimit: number;
    CreditLimitBlockage: boolean;
    InvoiceSeparately: boolean;
    VAT: boolean;
    CurrencyCode: string;
    MoneyRate: number;
    CardNumber: number;
    Region: CustomerRegion;
    Users: UserModel[];
}
