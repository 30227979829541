<div class="container-fluid h-100 background"
  [ngStyle]="{'background': ctx.Layout.Background ? 'url(\'/assets' + ctx.Layout.Background + '\') no-repeat center center fixed' : '', '-webkit-background-size': 'cover', '-moz-background-size': 'cover', 'background-size': 'cover', '-o-background-size': 'cover'}">
  <div class="row justify-content-center align-items-center h-100" [style]="ctx.Layout.BackgroundCss">
    <div class="col col-sm-6 col-md-6 col-lg-4 col-xl-3">
      <div class="card border-primary login-panel">
        <div class="card-header  text-bg-primary"
          (contextmenu)="authService.chooseVersion($event, ctx.Grossiers, wholesalerChange)">
          <span *ngIf="authService.multiLogonData && authService.multiLogonData.length > 1"
            (click)="authService.chooseCookieList(ctx.Wholesaler, false)" class="float-end pointer"><i
              class="fas fa-users"></i></span>
          <span *ngIf="!ctx.Layout.LoginPanelLogo" class="card-title">Login</span>
          <img *ngIf="ctx.Layout.LoginPanelLogo" [src]="'/assets' + ctx.Layout.LoginPanelLogo" />
        </div>
        <div class="card-body">
          <form class="row">
            <div class="col-12">
              <input class="form-control my-2" [appAutofocus]="true" placeholder="Loginnaam" name="Username"
                [(ngModel)]="authService.loginData.Username" type="text" (keyup.enter)="keytab($event)">
            </div>
            <div class="col-12">
              <input class="form-control my-2" placeholder="Wachtwoord" name="Password"
                [(ngModel)]="authService.loginData.Password" [type]="isPwVisible ? 'text' : 'password'"
                (dblclick)="isPwVisible = mainService.swicthIfWithAltShift($event, isPwVisible)"
                (keyup.enter)="authService.doLogin(ctx.Wholesaler)">
            </div>
            <div class="col-2"></div>
              <div class="col-8">
                <div class="form-check m-2">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" name="flexCheckDefault"
                    [(ngModel)]="authService.loginData.KeepLogin">
                  <label class="form-check-label text-nowrap" for="flexCheckDefault">Logingegevens onthouden</label>
                </div>

              </div>
              <div class="col-2"></div>
              <div class="col-12"><button class="btn btn-primary w-100" (click)="authService.doLogin(ctx.Wholesaler)">Login</button></div>
            <app-manage-account></app-manage-account>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>