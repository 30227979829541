import { KeyValue } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddressModel } from 'app/_models/admin/supplier-interface/address.model';
import { InterfaceKindInfoModel } from 'app/_models/admin/supplier-interface/interface.kind.info.model';
import { SupplierInterfaceData } from 'app/_models/admin/supplier-interface/supplier.interface.data';
import { SupplierInterfaceResponse } from 'app/_models/admin/supplier-interface/supplier.interface.response';
import { BranchModel } from 'app/_models/common/branch.model';
import { AdminSupplierInterfaceService } from 'app/_services/admin/admin-supplier-interface.service';
import { AdminService } from 'app/_services/admin/admin.service';
import { MainService } from 'app/_services/main.service';

@Component({
  selector: 'app-admin-supplier-info',
  standalone: false,
  templateUrl: './admin-supplier-info.component.html',
  styleUrl: './admin-supplier-info.component.scss'
})
export class AdminSupplierInfoComponent implements OnInit {
  supplierInterfaceData: SupplierInterfaceData;
  interfaceKinds: InterfaceKindInfoModel[];
  interfaceKindInfo: InterfaceKindInfoModel;
  supplier: number;
  suppliers: { id: number, name: string }[];
  branch: number;
  branches: { id: number, name: string }[];
  itemNumber: string;
  itemGroup: string;
  response: SupplierInterfaceResponse;
  busy = false;

  constructor(
    public adminSupplierInterfaceService: AdminSupplierInterfaceService,
    public adminService: AdminService,
    public mainService: MainService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.data
      .subscribe((data: any) => {
        this.supplierInterfaceData = data.supplierInterfaceData;
        const interfaceKindInfos = this.adminSupplierInterfaceService
          .getSortedInterfaceKindInfos(this.supplierInterfaceData.InterfaceKindInfos)
          .filter(interfaceKind => {
            let ok = false;
            if (this.supplierInterfaceData.InterfaceData.some(i => i.IntType === interfaceKind.InterfaceKind)) { ok = true; }
            return ok;
          });
        this.interfaceKinds = interfaceKindInfos; //Object.fromEntries(interfaceKindInfos.map(item => [item.InterfaceKind, item]));
      });
  }

  getSuppliers(intType: number): { id: number, name: string }[] {
    this.response = undefined;
    this.supplier = undefined;
    this.branch = undefined;
    const suppliers: { [key: number]: AddressModel } = {};
    this.supplierInterfaceData.InterfaceData.forEach(interfaceData => {
      if (interfaceData.IntType === intType && !suppliers[interfaceData.Supplier]) {
        suppliers[interfaceData.Supplier] = this.supplierInterfaceData.SupplierData[interfaceData.Supplier];
      }
    });
    const result = Object.keys(suppliers).map((key) => {
      return {
        id: +key,
        name: +key != 0 ? suppliers[key].Name as string : 'Onbekend'
      }
    });
    if (result.length === 1) {
      this.supplier = result[0].id;
      this.branches = this.getBranches(this.interfaceKindInfo.InterfaceKind, this.supplier);
    }
    return result.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
  }

  getBranches(intType: number, supplier: number): { id: number, name: string }[] {
    this.branch = undefined;
    const branches: { [key: number]: BranchModel } = {};
    this.supplierInterfaceData.InterfaceData.forEach(interfaceData => {
      if (interfaceData.IntType === intType && interfaceData.Supplier === supplier && !branches[interfaceData.Branch]) {
        branches[interfaceData.Branch] = this.supplierInterfaceData.Branches[interfaceData.Branch];
      }
    });
    const result = Object.keys(branches).map((key) => {
      return {
        id: +key,
        name: branches[key].BranchName as string
      }
    });
    if (result.length === 1) {
      this.branch = result[0].id;
    }
    return result.sort((a, b) => a.id - b.id);
  }

  supplierInterfaceDoInfo(itemNumber: string, itemGroup: string) {
    this.busy = true;
    let interfaceData = undefined;
    this.supplierInterfaceData.InterfaceData.forEach(id => {
      if ((interfaceData === undefined) && (id.IntType === this.interfaceKindInfo?.InterfaceKind) && (this.supplier === undefined || id.Supplier === this.supplier) && (this.branch === undefined || id.Branch === this.branch)) {
        interfaceData = id;
      }
    });
    this.adminService.supplierInterfaceDoInfo(interfaceData, itemNumber, itemGroup)
      .subscribe(response => {
        this.response = response;
        this.busy = false;
      });
  }


}
